import styled from '@emotion/styled';
import Image from 'components/publicWebsiteV2/common/Image';

export const SEVEN_BY_FIVE_IMAGE_WIDTH_PERCENTAGE = 66;
export const SevenByFiveImage = styled.img`
  width: ${SEVEN_BY_FIVE_IMAGE_WIDTH_PERCENTAGE}%;
  aspect-ratio: 7 / 5;
`;
export const SevenByFiveImageSized = styled(Image)`
  width: ${SEVEN_BY_FIVE_IMAGE_WIDTH_PERCENTAGE}%;
  aspect-ratio: 7 / 5;
`;

export const FIVE_BY_SEVEN_IMAGE_WIDTH_PERCENTAGE = 34;
export const FiveBySevenImageSized = styled(Image)`
  width: ${FIVE_BY_SEVEN_IMAGE_WIDTH_PERCENTAGE}%;
  aspect-ratio: 5 / 7;
`;
export const FiveBySevenImage = styled.img`
  width: ${FIVE_BY_SEVEN_IMAGE_WIDTH_PERCENTAGE}%;
  aspect-ratio: 5 / 7;
`;

export const DesktopImagesContainer = styled.div`
  position: relative;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-flow: row;
`;
